import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { RoutesService } from './shared/model/routes.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { NgForIn } from './ngforin.directive';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NgForIn
],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [RoutesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
