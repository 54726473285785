import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Route } from './route';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

constructor(private af : AngularFireDatabase) { }

findAllRoutes() : AngularFireList<Route> {
  return this.af.list('routes/j1CJZJrCvHhH8vAiCevA1wAErRk2');
}

}
