import { Component, OnInit } from '@angular/core';
import { RoutesService } from '../shared/model/routes.service';
import { Route } from '../shared/model/route';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  routes : Route[];

  constructor(private routesService : RoutesService) { }

  ngOnInit() {
    this.routesService
        .findAllRoutes()
        .valueChanges()
        .do(console.log)
        .subscribe(
          routes => this.routes = routes
        );
  }

}
