import { Component } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'my App';

  //courses$: Observable<any>;
  routenames = ["a", "b"];

  constructor(private af: AngularFireDatabase){

    //af.list<any>('routes/j1CJZJrCvHhH8vAiCevA1wAErRk2').valueChanges().subscribe(console.log);
    
  }

}
